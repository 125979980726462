export const words = [
  "of",
  "to",
  "in",
  "is",
  "on",
  "by",
  "it",
  "or",
  "be",
  "at",
  "as",
  "an",
  "we",
  "us",
  "if",
  "my",
  "do",
  "no",
  "up",
  "so",
  "am",
  "me",
  "go",
  "id",
  "oh",
  "ad",
  "ok",
  "hi",
  "pa",
  "ma",
  "ex",
  "da",
  "ha",
  "ya",
  "bi",
  "ho",
  "pi",
  "um",
  "yo",
  "aw",
];
